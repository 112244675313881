<!-- 详情弹窗 -->
<template>
  <div class="">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="50%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 人员信息 -->
      <a-descriptions
        style="
          word-break: break-word;
          word-wrap: break-word;
          white-space: pre-wrap;
        "
        layout="vertical"
        title="xx集团"
      >
        <a-descriptions-item label="法定代表人">
          {{ info.residentName || "--" }}
        </a-descriptions-item>
        <a-descriptions-item label="统一社会信用代码">
          {{ info.phone || "--" }}
        </a-descriptions-item>
        <a-descriptions-item label="登记状态">{{
          info.createTime || "--"
        }}</a-descriptions-item>
        <a-descriptions-item label="注册资本">{{
          info.createTime || "--"
        }}</a-descriptions-item>
        <a-descriptions-item label="实缴资本">{{
          info.createTime || "--"
        }}</a-descriptions-item>
        <a-descriptions-item label="企业类型">{{
          info.createTime || "--"
        }}</a-descriptions-item>
        <a-descriptions-item label="注册地址">{{
          info.createTime || "--"
        }}</a-descriptions-item>
      </a-descriptions>
      <!-- footer -->
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="visible = false"
          type="primary"
          style="background-color: #556bff"
          >返 回</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import api from "../api.js";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {  },
  data() {
    //这里存放数据
    return {
      api,
      visible: false,
      showPic: false,
      businessId: "",
      info: {},
      title: "订单详情",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    businessId(newval) {
      this.businessId = newval;
    },
  },
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      // this.getPointsById(record.id);
    },
    // 根据id查详情
    async getPointsById(id) {
      try {
        const res = await api.getPointsById(id);
        this.info = res.data;
        this.showPic = true;
        this.businessId = res.data.id;
        this.$refs.form1.setFieldsValue(res.data);
        this.$refs.form.setFieldsValue(res.data);
      } catch (error) {
        console.error(error); // 处理错误
      }
    },
    closeModal() {
      this.visible = false;
      this.refreshTable();
    },
    // 刷新表格
    refreshTable() {
      this.$emit("refresh");
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
  <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__title {
  color: #2d3138;
}
/deep/.ant-descriptions-view {
  padding-left: 1%;
}
/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #2a5cff;
  padding-left: 0.5%;
  line-height: 100%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
  padding-left: 4px;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}
// .titleBox {
//   display: flex;
//   align-items: center;
//   font-size: 0.08rem;
//   font-weight: 700;
//   color: #2d3138;
//   margin-bottom: 10px;
//   .titleLine {
//     height: 0.09rem;
//     width: 0.015rem;
//     background-color: #2a5cff;
//     margin-right: 5px;
//   }
// }
.orange {
  color: #e6a23c;
}
.green {
  color: #67c23a;
}
.red {
  color: red;
}
</style>